import React, { useEffect, useRef, useState } from "react";
// import './sports_search_panel.css';
import axios from "axios";
import Autocomplete from "react-autocomplete";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ar from "date-fns/locale/ar";
import produce from "immer";
import "react-spring-bottom-sheet/dist/style.css";
import MobileViewBottomSheet from "../../common/MobileViewBottomSheet";
import { addDays } from "date-fns";

//daetepicker for mobole view
import "react-dates/initialize";

import { DayPicker } from "react-dates";
import { DateRange } from "react-date-range";

import "react-dates/lib/css/_datepicker.css";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import {
  getSportsResultUrl,
  sportsSearchReq,
} from "../../../services/sports/SportsResult";
import { setSportsSearchData } from "../../../redux/sports/sportsSearch";
import Popup from "react-popup";
import moment from "moment";
import { setSportsElkLog } from "../../../services/elk/elk";

function SportsSearchpanel() {

  //setting for date to start from sunday in arabic
  const customArLocale = {
    ...ar,
    options: {
      // Modify options as needed:
      weekStartsOn: 0, // Set Sunday as the first day of the week
      firstWeekContainsDate: 7, // Consider the first week to contain January 7th
      // Add any other options you want to configure
    },
  };

  const weekDayFormatArr = [
    "الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"
  ]
  const weekDayFormatShortArr = [
    "ح", "ن", "ث", "ر", "خ", "ج", "س"
  ]

  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.culture.language);
  const loginDetails = useSelector((state) => state.loginDetail.loginDetail);

  const current_culture = useSelector((state) => state.culture.currentCulture);
  const country = useSelector((state) => state.currencyDetails.currencyCountry);

  const [team, setTeam] = useState([]);
  const [TeamLabel, setTeamLabel] = useState("");
  const [teamLabelArabic, setTeamLabelArabic] = useState("");
  const [teamSelected, setTeamSelected] = useState("");
  const [teamType, setTeamType] = useState("");

  const [fromDate, setFromDate] = useState(new Date());
  const [fromDateCalendar, setFromDateCalendar] = useState();
  const [toDateCalendar, setToDateCalendar] = useState();
  const openToDatepicker = () => toDateCalendar.setOpen(true);
  const [toDate, setToDate] = useState(new Date());
  const [toggleEvent, setToggleEvent] = useState(true);
  const [startCal, setStartCal] = useState();
  const [endCal, setEndCal] = useState();
  const [LocationLabel, setLocationLabel] = useState("");
  const [location, setLocation] = useState([]);
  const [locationSelected, setLocationSelected] = useState("");
  const [locationType, setLocationType] = useState("");
  const [redirectUrl, setRedirectUrl] = useState(false);
  const [isSearchBtnLoading, setSearchBtnLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedApiList, setSelectedApiList] = useState([]);
  const [instantDiscount, setInstantDiscount] = useState();
  const [instantMarkup, setInstantMarkup] = useState();
  const [isMobileViewBottomSheetOpen, setMobileViewBottomSheetOpen] = useState({
    isOpen: false,
    type: "",
  });

  const selectionRange = {
    startDate: startDate || new Date(),
    endDate: endDate || new Date(),
    key: "selection",
  };

  const [viewwidth, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = viewwidth <= 768;

  //end


  const userData = useSelector((state) => state.loginDetail.userData);
  //getting team options list
  const handleTeamRequestOptions = async (part) => {
    part = part.target.value;
    setTeamLabel(part);
    axios
      .get(
        process.env.REACT_APP_ELASTIC_URL +
        "Data/GetSportsEntity?value=" +
        part +
        "&culture=1&size=200"
      )
      .then((response) => {
        const jsonResponse = response.data;
        var TeamdataTemp = [];
        if (jsonResponse.hits.hits.length > 0) {
          jsonResponse.hits.hits.map((item) => {
            TeamdataTemp.push({
              TeamName: item._source.caption,
              TeamValue: item._source.id,
              TeamNameArr: item._source.captionarabic,
              TeamType: item._source.searchtype,
            });
          });
          setTeam(TeamdataTemp);
        }
      });
  };

  useEffect(() => {
    if (
      userData?.APIs?.["12"] &&
      Object.entries(userData?.APIs?.["12"]).length == 1
    ) {
      setSelectedApiList([Object.keys(userData?.APIs?.["12"])[0]]);
    }
  }, []);

  //team selection
  const handleTeamRequestSelection = (
    TeamName = "",
    item = { TeamValue: "" }
  ) => {
    if (current_culture == "en") {
      setTeamLabel(TeamName);
      setTeamSelected(item.TeamValue);
      setTeamType(item.TeamType);
      isMobile ? setMobileViewBottomSheetOpen({ isOpen: false, type: "" }) : fromDateCalendar.setOpen(true);
    } else {
      setTeamLabel(TeamName);
      setTeamLabelArabic(item.TeamNameArr);
      setTeamSelected(item.TeamValue);
      setTeamType(item.TeamType);
      isMobile ? setMobileViewBottomSheetOpen({ isOpen: false, type: "" }) : fromDateCalendar.setOpen(true);
    }


  };

  //from date input
  const FromDateInput = React.forwardRef(({ value, onClick }, ref) => {
    return (
      <div className="fieldWrap icon_calendar" onClick={onClick} ref={ref}>
        <input
          type="text"
          id=" "
          placeholder={lang.mob_start_date}
          value={moment(new Date(fromDate)).locale("en").format("DD/MM/YYYY")}
          name="lname"
          readOnly
        ></input>
      </div>
    );
  });
  const handleFromDateSelection = (date) => {
    /* const[startDate, endDate] = date
    setFromDate(startDate);

    
    // nextDate.setDate(nextDate.getDate() + 1);
    setToDate(endDate);
   /*  openToDatepicker(); */
    if (isMobile) {
      if (isMobileViewBottomSheetOpen.type == "startDate") {
        setFromDate(date.selection.startDate);
        setStartDate(date.selection.startDate);
      } else {
        setToDate(date.selection.endDate);
        setEndDate(date.selection.endDate);
      }
      setMobileViewBottomSheetOpen({ isOpen: false, type: "" });
    } else {
      setFromDate(date[0]);
      setStartDate(date[0]);
      if (date[1] == null) {
        setToDate(date[0]);
      } else {
        setToDate(date[1]);
      }
      setEndDate(date[1]);
    }


  };

  const ToDateInput = React.forwardRef(({ value, onClick }, ref) => {
    return (
      <div className="fieldWrap icon_calendar" onClick={onClick} ref={ref}>
        <input
          type="text"
          id=" "
          placeholder={lang.mob_end_date}
          value={moment(new Date(toDate)).locale("en").format("DD/MM/YYYY")}
          name="lname"
          readOnly
        ></input>
      </div>
    );
  });
  const handleToDateSelection = (date) => {
    setToDate(date);
    setToggleEvent(Date.now());
  };

  const handleLocationOptions = async (part) => {
    part = part.target.value;
    setLocationLabel(part);

    axios
      .get(
        process.env.REACT_APP_ELASTIC_URL +
        "Data/GetSportsLocation?value=" +
        part +
        "&culture=1&size=15"
      )
      .then((response) => {
        const jsonResponse = response.data;
        var FullLocationDataTemp = [];
        if (jsonResponse.hits.hits.length > 0) {
          jsonResponse.hits.hits.map((item) => {
            FullLocationDataTemp.push({
              LocationId: item._source.id,
              LocationName: item._source.caption,
              LocationNameArr: item._source.captionarabic,
              LocationType: item._source.searchtype,
            });
          });
          setLocation(FullLocationDataTemp);
        }
      });
  };
  const handleLocationRequestSelection = (
    LocationName = "",

    item = { LocationId: "" }
  ) => {
    if (current_culture) {
      setLocationLabel(LocationName);
      setLocationSelected(item.LocationId);
      setLocationType(item.LocationType);
      setMobileViewBottomSheetOpen({ isOpen: false, type: "" });
    } else {
      setLocationLabel(item.LocationNameArr);
      setLocationSelected(item.LocationId);
      setLocationType(item.LocationType);
      setMobileViewBottomSheetOpen({ isOpen: false, type: "" });
    }
  };

  ///clear button handler

  const clearButtonHandler = (type) => {
    if (type == "event") {
      setTeamLabel("");
      setTeamSelected("");
      setTeamType("");
    }
    if (type == "location") {
      setLocationLabel("");
      setLocationSelected("");
      setLocationType("");
    }
  };

  const handleFormSubmit = async () => {
    setSportsElkLog("Info", "home", "Sports search button clicked");
    if (teamSelected == "") {
      // alert('Enter valid Match or Location');
      Popup.create({
        title: lang.tathkarah_title,
        content: lang.enter_valid_match_or_location,
        buttons: {
          right: [lang.ok],
        },
      });
    } else if (LocationLabel != "" && locationSelected == "") {
      Popup.create({
        title: lang.tathkarah_title,
        content: lang.invalid_location_details,
        buttons: {
          right: [lang.ok],
        },
      });
    } else {
      setSportsElkLog("Info", "home", "Sports search validation passed");

      setSearchBtnLoading(true);
      let reqObject1 = {
        Entity: {
          Id: teamSelected,
          Type: teamType,
          Name: TeamLabel,
          NameArr: teamLabelArabic,
        },
        Location: {
          Id: locationSelected,
          Type: locationType,
          Name: LocationLabel,
        },
        SearchConfig: {
          FromDate: Moment(fromDate).format("DDMMMY"),
          FromDateFull: fromDate,
          ToDate: Moment(toDate).format("DDMMMY"),
          ToDateFull: toDate,
          Currency: "KWD",
          APIId: selectedApiList.length > 0 ? selectedApiList : null,
          Country:country,
        },
        Configuration: {
          IsBehalfMode: true,
          BehalfAgency: "",
          BehalfUser: "",
          NationalFare: false,
        },
        Utm: {
          Source: "",
          Medium: "",
          Campaign: "",
          Term: "",
          Content: "",
        },
        InstantCharge: {
          InstantMarkup: instantMarkup ? instantMarkup : null,
          InstantDiscount: instantDiscount ? instantDiscount : null,
        },
      };

      let reqObject = {
        teamId: teamSelected,
        teamName: TeamLabel,
        teamNameArr: teamLabelArabic,
        teamType: teamType,

        locationId: locationSelected,
        locationName: LocationLabel,
        locationType: locationType,

        fromDate: Moment(fromDate).format("DDMMMY"),
        toDate: Moment(toDate).format("DDMMMY"),

        InstantMarkup: instantMarkup ? instantMarkup : null,
        InstantDiscount: instantDiscount ? instantDiscount : null,
        
      };

      console.log("reqObject", reqObject);
      let sportsSearchResponse = await getSportsResultUrl(reqObject1);
      console.log(sportsSearchResponse);

      dispatch(setSportsSearchData(sportsSearchResponse));

      const sportSearchRes = await sportsSearchReq(
        sportsSearchResponse.searchId
      );

      if (sportSearchRes) {
        setSportsElkLog("Success", "home", "Sports search request completed");

        let url =
          current_culture +
          "/SportsResult" +
          "?SearchId=" +
          sportsSearchResponse.searchId;

        setRedirectUrl(process.env.REACT_APP_BASE_URL + url);
      }
    }
  };

  function selectedApiHandler(apiId) {
    setSelectedApiList((prvState) => {
      if (selectedApiList.includes(apiId)) {
        let index = selectedApiList.indexOf(apiId);
        return produce(prvState, (draft) => {
          draft.splice(index, 1);
        });
      } else {
        return produce(prvState, (draft) => {
          draft.push(apiId);
        });
      }
    });
  }

  const ApiList = () => {
    if (userData?.ShowAllowedApi && userData?.APIs?.["12"]) {
      return Object.entries(userData?.APIs?.["12"]).map(function (
        api,
        keyIndex
      ) {
        return (
          <div className="b2bapilist">
            <div className="hmcol_4 hmStops">
              <input
                type="checkbox"
                id="api1"
                name="IsDirect2"
                value="true"
                checked={selectedApiList.includes(api[0])}
                onChange={() => {
                  selectedApiHandler(api[0]);
                }}
              ></input>
              <label for="api1">
                {" "}
                <span>{api[1]}</span>{" "}
              </label>
            </div>
          </div>
        );
      });
    }
  };

  if (redirectUrl) {
    return <Navigate push={true} to={redirectUrl} />;
  }

  // //sports coming soon
  if (true) {
    return <div className="search_main search_main_sports ">
      <div className="search_lft" style={{color:"#fff"}}>
           {lang.coming_soon}
      </div>
    </div>
  }
  return (
    <>
      <div className="search_main search_main_sports ">
        <div className="search_lft">
          <div className="fieldbox_row">
            <div className="field_clm_01 field-mr-15 mob_bdr">
              <div className="fieldWrap icon_location DisplayinRmv autocomplete">
                <label className="mob_from_label">
                  {lang.mob_team_or_match}
                </label>
                {isMobile ?
                  <div onClick={() => {
                    setMobileViewBottomSheetOpen({
                      isOpen: true,
                      type: "event",
                    });
                  }}>
                    <input
                      type="text"
                      id=" "
                      value={TeamLabel}
                      placeholder={lang.sports_searchpannel_enter_team_name_match}
                      name="lname"
                      readOnly
                    ></input>
                  </div>
                  :
                  <Autocomplete
                    wrapperStyle={{ display: "inherit" }}
                    wrapperProps={{ className: "auto_complete" }}
                    getItemValue={(item) => item.TeamName}
                    items={team}
                    renderItem={(item, isHighlighted) => (
                      <div className="react-autocomplete" key={item.TeamValue}>
                        <div
                          className={
                            isHighlighted
                              ? " react-autocomplete-inner active "
                              : "react-autocomplete-inner"
                          }
                          key={item.TeamValue}
                          data-id={item.TeamValue}
                        >
                          {item.TeamName}
                        </div>
                      </div>
                    )}
                    inputProps={{
                      type: "text",
                      placeholder: lang.sports_searchpannel_enter_team_name_match,
                    }}
                    value={TeamLabel}
                    onChange={handleTeamRequestOptions}
                    onSelect={handleTeamRequestSelection}
                  />
                }

                {isMobileViewBottomSheetOpen.isOpen &&
                  isMobileViewBottomSheetOpen.type == "event" && (
                    <MobileViewBottomSheet
                      open={isMobileViewBottomSheetOpen.isOpen}
                      setOpen={setMobileViewBottomSheetOpen}
                      maxHeightProp={0.9}
                    >
                      <div className="mob_autocomplete_box mob_passenger_block">
                        <button
                          type="button"
                          className="close"
                          onClick={() =>
                            setMobileViewBottomSheetOpen({
                              isOpen: false,
                              type: "",
                            })
                          }
                        />
                        <h2>{lang.sports_mob_team_select}</h2>
                        <Autocomplete
                          wrapperStyle={{ display: "inherit" }}
                          wrapperProps={{ className: "auto_complete" }}
                          getItemValue={(item) => item.TeamName}
                          menuStyle={{ top: 0 }}
                          items={team}
                          renderItem={(item, isHighlighted) => (
                            <div className="react-autocomplete" key={item.TeamValue}>
                              <div
                                className={
                                  isHighlighted
                                    ? " react-autocomplete-inner active "
                                    : "react-autocomplete-inner"
                                }
                                key={item.TeamValue}
                                data-id={item.TeamValue}
                              >
                                {item.TeamName}
                              </div>
                            </div>
                          )}
                          inputProps={{
                            type: "text",
                            placeholder: lang.sports_searchpannel_enter_team_name_match,
                            ref: inputRef,
                          }}
                          value={TeamLabel}
                          onChange={handleTeamRequestOptions}
                          onSelect={handleTeamRequestSelection}
                        />

                      </div>
                    </MobileViewBottomSheet>
                  )
                }

                {TeamLabel && (
                  <a
                    className="clear_btn"
                    id="close_from_city"
                    onClick={() => {
                      clearButtonHandler("event");
                    }}
                  ></a>
                )}
                {/* {TeamLabel != null && TeamLabel != '' && (
								<a
									id="match_clear"
									className="clearBtn"
									style={{ top: '5%', right: '7px' }}
									onClick={() => {
										handleTeamRequestSelection();
									}}
								>
									X
								</a>
							)} */}
                {/* <input type="text" id=" " placeholder="Enter Team Name / Match" name="lname"></input> */}
              </div>
            </div>

            <div className="col_date ">
              <div className="field_clm_02 field-mr-15">
                <label className="mob_clndr_label">{lang.mob_start_date}</label>
                {isMobile ?
                  <FromDateInput onClick={() => {
                    setMobileViewBottomSheetOpen({
                      isOpen: true,
                      type: "startDate",
                    });
                  }} />
                  :
                  <DatePicker
                    ref={(c) => setFromDateCalendar(c)}
                    dateFormat={"MMM dd yyyy"}
                    minDate={new Date()}
                    selected={fromDate}
                    onChange={handleFromDateSelection}
                    customInput={<FromDateInput />}
                    placeholder={lang.mob_start_date}
                    disabledKeyboardNavigation
                    monthsShown={2}
                    selectsRange
                    startDate={startDate}
                    endDate={endDate}
                    locale={current_culture == "en" ? "" : customArLocale}
                    formatWeekDay={(nameOfDay, index) => {
                      let weeDayIndex = weekDayFormatArr.indexOf(nameOfDay)
                      return current_culture == "en" ? nameOfDay.substr(0, 3) : weekDayFormatShortArr[weeDayIndex]
                    }}
                    focusSelectedMonth={true}
                  />}
                {isMobileViewBottomSheetOpen.isOpen &&
                  isMobileViewBottomSheetOpen.type == "startDate" && (
                    <MobileViewBottomSheet
                      open={isMobileViewBottomSheetOpen.isOpen}
                      setOpen={setMobileViewBottomSheetOpen}
                      maxHeightProp={0.9}
                    >

                      <div className="mob_autocomplete_box mob_passenger_block">
                        <button
                          type="button"
                          className="close"
                          onClick={() =>
                            setMobileViewBottomSheetOpen({
                              isOpen: false,
                              type: "",
                            })
                          }
                        />
                        <h2>Start Time</h2>
                        <div
                          className="mob_calender"
                          style={{ height: 568, width: 320 }}
                        >
                          <DateRange
                            ranges={[selectionRange]}
                            onChange={handleFromDateSelection}
                            direction="vertical"
                            scroll={{ enabled: true }}
                            minDate={new Date()}
                            maxDate={addDays(new Date(), 365)}
                            showDateDisplay={false}
                            months={3}
                          />
                        </div>
                      </div>
                    </MobileViewBottomSheet>
                  )
                }
              </div>
              <div className="field_clm_02 field-mr-15">
                <label className="mob_clndr_rtn_label">
                  {lang.mob_end_date}
                </label>
                <ToDateInput onClick={() => isMobile ? setMobileViewBottomSheetOpen({
                  isOpen: true,
                  type: "endDate",
                }) : fromDateCalendar.setOpen(true)} />
                {/* <DatePicker
                ref={(c) => setToDateCalendar(c)}
                dateFormat={"MMM dd yyyy"}
                minDate={fromDate}
                selected={toDate}
                onChange={handleToDateSelection}
                customInput={<ToDateInput />}
                disabledKeyboardNavigation
              /> */}
                {isMobileViewBottomSheetOpen.isOpen &&
                  isMobileViewBottomSheetOpen.type == "endDate" && (
                    <MobileViewBottomSheet
                      open={isMobileViewBottomSheetOpen.isOpen}
                      setOpen={setMobileViewBottomSheetOpen}
                      maxHeightProp={0.9}
                    >
                      <div className="mob_autocomplete_box mob_passenger_block">
                        <button
                          type="button"
                          className="close"
                          onClick={() =>
                            setMobileViewBottomSheetOpen({
                              isOpen: false,
                              type: "",
                            })
                          }
                        />
                        <h2>Until</h2>
                        <div className="mob_calender"
                          style={{ height: 568, width: 320 }}
                        >
                          <DateRange
                            ranges={[selectionRange]}
                            onChange={handleFromDateSelection}
                            direction="vertical"
                            scroll={{ enabled: true }}
                            minDate={startDate}
                            maxDate={addDays(new Date(), 365)}
                            showDateDisplay={false}
                            months={3}
                          />
                        </div>
                      </div>
                    </MobileViewBottomSheet>
                  )
                }
              </div>
            </div>

            <div className="field_clm_01 field-mr-15 mob_bdr">
              <div className="fieldWrap icon_location DisplayinRmv autocomplete">
                <label className="mob_from_label">{lang.mob_location}</label>
                {/* <div className="fieldWrap has__clearbtn"> */}
                {isMobile ?
                  <input
                    type="text"
                    id=" "
                    value={LocationLabel}
                    placeholder={lang.search_pannel_location}
                    name="lname"
                    onClick={() => {
                      setMobileViewBottomSheetOpen({
                        isOpen: true,
                        type: "location",
                      });
                    }}
                    readOnly
                  ></input>
                  :
                  <Autocomplete
                    wrapperStyle={{ display: "inherit" }}
                    wrapperProps={{ className: "auto_complete" }}
                    getItemValue={(item) => item.LocationName}
                    items={location}
                    renderItem={(item, isHighlighted) => (
                      <div className="react-autocomplete" key={item.LocationId}>
                        <div
                          className={
                            isHighlighted
                              ? " react-autocomplete-inner active "
                              : "react-autocomplete-inner"
                          }
                          key={item.LocationId}
                          data-id={item.LocationId}
                        >
                          {item.LocationName}
                        </div>
                      </div>
                    )}
                    inputProps={{ placeholder: lang.search_pannel_location }}
                    value={LocationLabel}
                    onChange={handleLocationOptions}
                    onSelect={handleLocationRequestSelection}
                  />
                }
                {isMobileViewBottomSheetOpen.isOpen &&
                  isMobileViewBottomSheetOpen.type == "location" && (
                    <MobileViewBottomSheet
                      open={isMobileViewBottomSheetOpen.isOpen}
                      setOpen={setMobileViewBottomSheetOpen}
                      maxHeightProp={0.9}
                    >
                      <div className="mob_autocomplete_box">
                        <h2>{lang.search_pannel_location}</h2>
                        <div className="mob_calender"
                          style={{ height: 568, width: 320 }}
                        >
                          <Autocomplete
                            wrapperStyle={{ display: "inherit" }}
                            wrapperProps={{ className: "auto_complete" }}
                            getItemValue={(item) => item.LocationName}
                            menuStyle={{ top: 0 }}
                            items={location}
                            renderItem={(item, isHighlighted) => (
                              <div className="react-autocomplete" key={item.LocationId}>
                                <div
                                  className={
                                    isHighlighted
                                      ? " react-autocomplete-inner active "
                                      : "react-autocomplete-inner"
                                  }
                                  key={item.LocationId}
                                  data-id={item.LocationId}
                                >
                                  {item.LocationName}
                                </div>
                              </div>
                            )}
                            inputProps={{ placeholder: lang.search_pannel_location }}
                            value={LocationLabel}
                            onChange={handleLocationOptions}
                            onSelect={handleLocationRequestSelection}
                          />
                        </div>
                        <div className="mob_auto_hd">
                          <h3>Recent Searches</h3>
                          <span /* onClick={cookieRemoveHandler} */>Clear</span>
                        </div>

                        <div className="mob_auto_list">
                          {/*  <ul>
                                {recentSearchData.map((item) => {
                                  return <li onClick={()=>{
                                    selectAirportHandler(item.label, item, "depart")
                                  }}> {item.label}</li>;
                                })}
                              </ul> */}
                        </div>
                      </div>
                    </MobileViewBottomSheet>
                  )
                }

                {LocationLabel && (
                  <a
                    className="clear_btn"
                    id="close_from_city"
                    onClick={() => {
                      clearButtonHandler("location");
                    }}
                  ></a>
                )}
                {/* {LocationLabel != null && LocationLabel != '' && (
							<a
								id="match_clear"
								className="clearBtn"
								style={{ top: '4%', right: '7px' }}
								onClick={() => {
									handleLocationRequestSelection();
								}}
							>
								X
							</a>
            )} */}
                {/* </div> */}
              </div>
            </div>
          </div>
          {
            /* loginDetails?.Role == "Admin" && */
            /* userData && */ userData?.Permissions &&
            (Object.values(userData.Permissions).includes(
              "Show Instant Markup"
            ) ||
              Object.values(userData.Permissions).includes(
                "Show Instant Discount"
              )) && (
              <div className="search_main search_main_sports sportinstant">
                <div className="instant_markup">
                  {Object.values(userData.Permissions).includes(
                    "Show Instant Markup"
                  ) && (
                      <div
                        className="auto_complete instant_discount"
                        style={{ display: "inherit" }}
                      >
                        <input
                          placeholder={lang.instant_markup}
                          onChange={(evt) => {
                            evt.target.value = evt.target.value.replace(
                              /^(?!\d*\.?\d*$).*/,
                              ""
                            );
                            setInstantMarkup(evt.target.value);
                          }}
                        />
                      </div>
                    )}
                  {Object.values(userData.Permissions).includes(
                    "Show Instant Discount"
                  ) && (
                      <div
                        className="auto_complete "
                        style={{ display: "inherit" }}
                      >
                        <input
                          placeholder={lang.instant_discount}
                          onChange={(evt) => {
                            evt.target.value = evt.target.value.replace(
                              /^(?!\d*\.?\d*$).*/,
                              ""
                            );
                            setInstantDiscount(evt.target.value);
                          }}
                        />
                      </div>
                    )}
                </div>
              </div>
            )
          }
          <ApiList></ApiList>
        </div>
        <div className="search_rht">
          {!isSearchBtnLoading ? (
            <button
              className="button_theme searchBtn"
              onClick={handleFormSubmit}
            >
              <i className="icon_search"></i>
              <span>{lang.search}</span>
            </button>
          ) : (
            <div className="loader-sm" id="SprtsBtnSearch">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default SportsSearchpanel;
